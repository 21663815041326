import React, { FC, ReactNode } from 'react'

import { Icons } from '../Icon/types'
import { LinkProps } from '../Link'
import { light } from '../../theme/palette'
import { ImgProps } from '../../types/image'
import { ImageProps } from '../Image'

import * as SC from './styled'

export type ReasonProps = {
  text: string
}

export type NotFoundProps = {
  className?: string
  title?: string
  body?: ReactNode
  cta?: LinkProps
  image?: ImageProps
  backgroundImage?: ImgProps
  backgroundColor: string
}

const NotFound: FC<NotFoundProps> = (props) => {
  const { className, title, body, image, cta, backgroundColor, backgroundImage } = props

  return (
    <SC.NotFound className={className} backgroundColor={backgroundColor}>
      <SC.Wrapper maxWidth="xl" disableGutters>
        <SC.Content>
          <SC.Title>{title}</SC.Title>
          <SC.Body>{body}</SC.Body>
          {cta && (
            <SC.Cta
              {...cta}
              variant="outlined"
              icon={Icons.longArrowRight}
              iconColor={light.colors.freeSpeechBlue}
            />
          )}
        </SC.Content>
        <SC.BoxImage>
          <SC.StyledImage {...image} />
        </SC.BoxImage>
      </SC.Wrapper>
      {backgroundImage && (
        <SC.BoxBackgroundImage>
          <SC.BackgroundImage src={backgroundImage.src} alt={backgroundImage.alt} />
        </SC.BoxBackgroundImage>
      )}
    </SC.NotFound>
  )
}

export default NotFound
