import React, { FC, memo } from 'react'
import { PageProps } from 'gatsby'

import { Contentful_Configuration, Contentful_PageCollection } from '../../types/gatsby-types'
import App from '../../components/App'
import { SeoProps } from '../../components/Seo'
import { ImageTransformer, RichTextTransformer } from '../../relay/models'
import NotFoundComponent from '../../components/NotFound'
import PageTemplate from '../../templates/Page'
import { useHeader } from '../../hooks/useHeader'
import { useFooter } from '../../hooks/useFooter'

interface NotFoundPage extends PageProps {
  pageContext: {
    locale: string
    urls: Contentful_PageCollection | null
    configuration: Contentful_Configuration
  }
}

const NotFound: FC<NotFoundPage> = ({ pageContext, location }) => {
  const { configuration, locale } = pageContext

  const titleElement = configuration?.notFound?.title as string
  const bodyElement = RichTextTransformer(configuration?.notFound?.body?.json)
  const backgroundImage = {
    alt: 'LiberteClasse - Background - NotFound',
    src: '/images/common/cloud.png',
  }
  const image = configuration?.notFound?.image
    ? ImageTransformer(configuration?.notFound?.image)
    : undefined
  const cta = {
    text: configuration?.notFound?.cta?.label as string,
    link: configuration?.notFound?.cta?.url as string,
    target: configuration?.notFound?.cta?.target as string,
  }

  const { headerProps } = useHeader(configuration, locale, location, { color: '#F1F3FF' })
  const { footerProps } = useFooter(configuration, locale)

  return (
    <App seo={configuration?.seo as SeoProps} configuration={configuration} locale={locale}>
      <PageTemplate headerProps={headerProps} footerProps={footerProps}>
        <NotFoundComponent
          title={titleElement}
          body={bodyElement}
          backgroundImage={backgroundImage}
          backgroundColor={'#F1F3FF'}
          image={image}
          cta={cta}
        />
      </PageTemplate>
    </App>
  )
}

export default memo(NotFound)
