import styled from 'styled-components'
import { Container } from '@material-ui/core'

import LinkButton from '../LinkButton'
import Image from '../Image'

export const NotFound = styled.div<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Wrapper = styled(Container)`
  padding: 1rem 1rem 0 1rem;
  z-index: 5;
  position: relative;
  ${(props) => props.theme.breakpoints.up('md')} {
    display: flex;
    flex-direction: row;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10;
  max-width: 40rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    width: 70rem;
    max-width: 80rem;
    justify-content: center;
    margin: 10rem 11rem 13rem 13rem;
  }
`

export const Title = styled.h1`
  ${(props) => ({ ...props.theme.typography.h3 })};
  color: ${(props) => props.theme.palette.colors.tomato};
  font-size: 2.4rem;
  margin: 0;
  padding: 1rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 1rem 0;
  }
`

export const Body = styled.div`
  padding: 1rem;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 1rem 0;
  }
  ${(props) => ({ ...props.theme.typography.mediumRegular })};
  color: ${(props) => props.theme.palette.colors.chambray};
  & ul {
    padding: 0 0 0 1.6rem;
    margin: 0;
  }
  & li {
    ${(props) => ({ ...props.theme.typography.mediumRegular })};
    color: ${(props) => props.theme.palette.colors.chambray};
    & p {
      padding: 0;
      margin: 0;
    }
  }
`

export const Cta = styled(LinkButton)`
  margin: 1rem;
  max-width: max-content;
  border-color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
  & > span {
    color: ${(props) => props.theme.palette.colors.freeSpeechBlue};
    ${(props) => ({ ...props.theme.typography.h5 })}
  }
  &:hover {
    background-color: ${(props) => props.theme.palette.colors.cornflowerBlue};
    border-color: ${(props) => props.theme.palette.colors.cornflowerBlue};
    & > span {
      color: ${(props) => props.theme.palette.colors.pureWhite};
    }
    & > svg {
      color: ${(props) => props.theme.palette.colors.pureWhite};
    }
  }
`

export const BoxImage = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  ${(props) => props.theme.breakpoints.up('md')} {
    align-self: flex-end;
  }
`

export const StyledImage = styled(Image)`
  height: auto;
  width: 32rem;
  z-index: 10;
  ${(props) => props.theme.breakpoints.up('md')} {
    position: absolute;
    bottom: 0%;
    height: auto;
    width: 64rem;
    max-width: 64rem;
  }
`

export const BoxBackgroundImage = styled.div`
  position: absolute;
  bottom: -34px;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    width: 1062px;
  }
  ${(props) => props.theme.breakpoints.up('md')} {
    & img {
      width: auto;
    }
  }
`

export const BackgroundImage = styled.img``
